// Follow up: should this live in Tacklebox?

export const localStorageAuthInfoKey = "nio:marketplace-app-user"

export function readContext(key) {
  try {
    return localStorage.getItem(key)
  } catch (e) { return null }
}

export function readContextAsJson(key) {
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch (e) { return null }
}

export function setContext(key, data) {
  localStorage.setItem(key, data)
}

export function deleteContext(key) {
	localStorage.removeItem(key)
}	

export function addMessageListener(handler) {
	if (window.addEventListener) {
		window.addEventListener("message", handler, false);
	}
	else {
		window.attachEvent("onmessage", handler);
	}
}
