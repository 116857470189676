<template lang="pug">
  #app
    v-app
      .header
        h1.nio-h1.text-primary-darker Touchless PII Hasher
        NioSelect.select(
          v-model="selectedSettings" 
          :items="availableSettings"
          label="Hash Settings"
          item-text="name"
          item-value="value" 
          hide-selections
          small
          multiple
        )
          template(v-slot:selection)
      Chooser(
        :selected-settings="selectedSettings"
        :valid="valid"
      )  
</template>

<script>

import Chooser from './components/Chooser'
import NioSelect from '@narrative.io/tackle-box/src/components/Select'
import * as auth from '@/modules/auth.module'

export default {
  name: 'App',
  components: { Chooser },
  data: () => ({
    user: null,
    appName: 'Touchless PII Hasher',
    valid: true,
    isAuthenticated: null,
     availableSettings: [
      {
        name: 'SHA-256 Hash',
        value: "sha256"
      },  
      {
        name: 'SHA-1 Hash',
        value: "sha1"
      },  
      {
        name: 'MD5 Hash',
        value: "md5"
      },  
      {
        name: 'Include Original',
        value: "preserve_original"
      },  
      {
        name: 'Remove Invalid Emails',
        value: "remove_invalid_emails"
      }

    ],
    selectedSettings: ["sha256", "sha1", "md5", "remove_invalid_emails"]
  }),
  watch: {
    selectedSettings(val) {
      if (!val.length) {
        this.valid = false
      } else if((val.includes('sha256') || val.includes('sha1') || val.includes('md5'))) {
        this.valid = true
      } else {
        this.valid = false
      }
    }
  },
  mounted() {
    this.observeDocumentHeight()
    if (window.addEventListener) {
      window.addEventListener("message", this.handleMessage, false);
    }
    else {
      window.attachEvent("onmessage", this.handleMessage);
    }
  },
  methods: {
    observeDocumentHeight() {	
      const resizeObserver = new ResizeObserver((val) => {
        parent.postMessage({
          name: 'heightUpdated',
          payload: Math.ceil(val[0].contentRect.height)
        },"*")
      });
      resizeObserver.observe(document.getElementsByTagName('body')[0])
    },
    handleMessage (evt) {
      switch (evt.data.name) {
        case 'auth':
          this.user = evt.data.payload.user
          auth.setContext('token', evt.data.payload.token)
          this.mockAuthenticateApiCall(this.appName, this.user.id, evt.data.payload.token).then(resp => { // api call to get auth token
            if (resp.isAuthenticated) {
              this.isAuthenticated = true
            }
          })
          break;
      
        default:
          break;
      }
    },
    mockAuthenticateApiCall(appName, userId, token) {
      return new Promise((resolve, reject) => {
        if (userId === 1 && token === 'xxx') {
          resolve({isAuthenticated: true})
        } else {
          resolve({isAuthenticated: false})
        }
      })
    }
  } 
}
</script>

<style lang="sass" scoped>
@import '@narrative.io/tackle-box/src/styles/global/_fonts'
@import '@narrative.io/tackle-box/src/styles/global/_colors'
@import '@narrative.io/tackle-box/src/styles/global/_color-helpers'
@import '@narrative.io/tackle-box/src/styles/global/_typography'

::v-deep 
  .v-application
    font-family: map-get($font-families, base)
    .v-application--wrap
      padding: 24px
      .header
        display: flex
        justify-content: space-between
        align-items: center
        margin-bottom: 1.75rem
        .select
          width: 265px
          flex-grow: 0
          margin-bottom: 0px
</style>

<style lang="sass" scoped>
  ::v-deep .v-application--wrap
    padding: 24px
    .nio-select .v-input__slot .v-label.v-label--active
      top: 2.4275rem !important
      font-size: 20px !important
</style>