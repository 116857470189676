<template lang="pug">
  .chooser
    NioFileChooser.file-chooser(
      v-model="file" 
      :percent-complete="percentComplete"
      :max-file-size="1024*1024*1024*10"
      :validate-fn="() => true"
      :valid="valid"
      :state="chooserState"
      :success-msg="`Your file contains ${numEmails} valid emails and ${numErrors} invalid emails.`"
      in-progress-msg="Hashing..."
      error-msg="Your file contained no valid emails"
      general-error-msg="Your file does not contain any valid emails."      
      instructions="Choose a .CSV or .TXT file containing raw emails."
      action-label="Generate Hash"
      invalid-msg="at least one hash option must be selected"
      @changed="loadTextFromFile($event)" 
      @actionClicked="execute"
      @cancelClicked="cancel"
    )
      template(v-slot:success-actions)        
        NioButton(
          normal-secondary 
          @click="reset"
        ) Start Over
        NioButton(
          v-if="!fileDownloaded && numEmails > 0"
          key="0"
          normal-primary 
          @click="downloadFile"
        ) Download File
        NioButton(
          v-else
          key="1"
          disabled
          normal-secondary
        ) File Downloaded
      template(v-slot:error-actions)        
        NioButton(
          normal-primary 
          @click="reset"
        ) Start Over		
    .hash-preview(:class="{'expanded': preview.length > 1}")
      v-divider
      .content
        h2.nio-h3.text-primary-darker Hash preview
        p.nio-p.text-primary-dark Your file’s first {{ preview.length }} rows of hashes
        .preview
          p.nio-mono-small.text-primary-dark(
            v-for="row in preview"
          ) {{ row }}
</template>

<script>
import * as fs from '@narrative.io/native-file-system-adapter'

export default {
  props: ["selectedSettings", "valid"],
  data: () => ({
    file: null,
    chooserState: 'initial',
    percentComplete: 0,
    numWorkers: 1,
    settings: ["md5"],
    file: null,
    downloadHandle: null,
    readStream: null,
    writeStream: null,
    writer: null,
    fileDownloaded: false,
    numEmails: 0,
    numErrors: 0,
    preview: []
  }),
  watch: {
    preview() {

    }
  },
  mounted() {
    const {
      showDirectoryPicker,
      showOpenFilePicker,
      showSaveFilePicker,
      FileSystemFileHandle,
      FileSystemHandle,
      FileSystemWritableFileStream,
    } = fs
    globalThis.fs = fs
  },	
  methods: {
    setState(val) {
      this.chooserState = val
    },
    updateProgress(val) {
      this.percentComplete = val
    },
    loadTextFromFile(files) {
      this.file = files[0]
    },
    execute() {
      this.createWorker()
      this.doWork(this.worker, this.file)
    },
    createWorker() {   
      this.worker = new Worker("../service-worker/worker.js", {
        type: "module",
      });
    },
    cancel() {
      this.preview = []
      if (this.writer) {
        this.writer.abort()
      }
      this.terminateWorker()
      this.percentComplete = 0
    },
    downloadFile() {
      this.closeWriter()
      this.fileDownloaded = true
    },
    reset() {
      this.chooserState = 'initial'
      this.fileDownloaded = false
      this.percentComplete = 0
      this.preview = []
    },
    async getDownloadFileHandle() {
      return await globalThis.fs.showSaveFilePicker({
        _preferPolyfill: true,
        _name: 'hashed_emails.csv', 
        types: {},
        excludeAcceptAllOption: false
      })
    },
    async writeChunk(chunk) {
      await this.writer.write(chunk)
    },
    async closeWriter() {
      this.percentComplete = 0
      await this.writer.close()
    },
    async doWork(worker, data) {
      this.chooserState = 'selected'
      this.downloadHandle = await this.getDownloadFileHandle()
      this.writer = await this.downloadHandle.createWritable()
      this.chooserState = 'inProgress'
      return new Promise((resolve, reject) => {
        this.worker.addEventListener("message", (e) => {
          switch (e.data.cmd) {
            case "preview":
              this.preview = e.data.payload
              break;
            case "returned":
              this.writeChunk(e.data.payload)
              resolve(new Blob([e.data.payload]));
              break;
            case "completed":
              this.numEmails = e.data.payload.numEmails
              this.numErrors = e.data.payload.numErrors
              if (this.numEmails > 0) {
                this.chooserState = 'success'
              } else {
                this.chooserState = 'error'
              }
              break;
            case "error": 
              console.error("something went wrong", e.data.cmd);
              reject("Main page doesn't understand command " + e.data.cmd);
              break;
            case "empty":
              resolve(new Blob([]));
              break;
            case "updateProgress":
              this.updateProgress(e.data.payload)
              break;
            default:
              console.error("something went wrong", e.data.cmd);
              reject("Main page doesn't understand command " + e.data.cmd);
              break;
          }
        });

        data.arrayBuffer().then((arrBuff) => {
          this.worker.postMessage({ 
            cmd: "start", 
            payload: arrBuff, 
            transformerArgs: {
              size: data.size
            },
            settings: this.selectedSettings
            }, [arrBuff]
          );
        });
      });
    },
    terminateWorker() {
      this.worker.terminate()
    }
  }
}
</script>

<style lang="sass" scoped>
@import '@narrative.io/tackle-box/src/styles/global/_colors'
@import '@narrative.io/tackle-box/src/styles/global/_color-helpers'
@import '@narrative.io/tackle-box/src/styles/global/_typography'

.hash-preview
  max-height: 0px
  height: 0px
  margin-top: 24px
  margin-bottom: 24px
  padding-top: 24px
  opacity: 0
  transition: all 0.08s ease-in
  position: relative
  h2
    margin-bottom: 8px
  p
    margin-bottom: 0px
  .v-divider
    border-color: $c-primary-lightest
    width: calc(100% + 48px)
    position: absolute
    top: 0px
    left: -24px
    max-height: unset
    max-width: unset
    display: none
  .content
    overflow: hidden
    height: 0px
    .preview
      padding: 24px
      border: 1.5px solid $c-primary-lightest
      border-radius: 12px
      margin-top: 24px
      overflow: scroll
  &.expanded
    opacity: 1
    max-height: 500px
    height: auto
    transition: all 0.08s ease-in
    .v-divider
      display: block
    .content
      height: unset
</style>
