import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import UILibrary from '@narrative.io/tackle-box'
import '@narrative.io/tackle-box/dist/tackle-box.css'
import AppModule from '@narrative.io/tackle-box/'

Vue.use(UILibrary)
Vue.use(AppModule)

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App),
}).$mount('#app')

